.skillBlock {
    border-radius: 10px;
    box-shadow: 0 0 5px;
    padding-bottom: 10px;
    height: 100%;
    .block-img {
        display: flex;
        justify-content: center;
        height: 25%;
        .circle-img {
            height: 150px;
            width: 150px;
            border-radius: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(50px);
            img {
                height: 100px;
                width: 100px;
            }
        }
    }
    .block-description {
        background-color: white;
        padding: 94px 12px 24px 12px;
        border-radius: 5px 5px 10px 10px;
        margin: 0px 10px;
        height: 75%;
        h2 {
            margin-bottom: 12px;
            font-size: 40px;
            text-align: center;
        }
        p {
        }
    }
}

@media screen and (max-width: 1080px) {.skillBlock {
    .block-img {
        height: 100px;
        .circle-img {
            height: 120px;
            width: 120px;
            border-radius: 60px;
            transform: translateY(30px);
            img {
                height: 70px;
                width: 70px;
            }
        }
    }
    .block-description {
        padding-top: 54px;
        h2 {
            text-align: center;
        }
        p {
            text-align: center;
        }
    }
}
}