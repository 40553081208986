.translate {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
    border-radius: 10px;;
    height: 20px;

    button {
        background: none;
        border: none;
        height: 20px;
        opacity: 0.5;
        
        img{
            width: 16px;
            margin: 2px;
        }

    }

    button:hover{
        cursor: pointer;
        border-radius: 10px;
        opacity: 1;
    }
}
