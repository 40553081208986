.achievement-block {
    .block-reverse {
        flex-direction: row-reverse;
    }
    .block {
        background-color: white;
        width: 100%;
        display: flex;
        padding: 0px 12px;
        .block-img {
            width: 60%;
            padding: 24px 12px;
            img {
                width: 100%;
                border-radius: 20px 20px 0px 20px;
            }
            .img-reverse {
                border-radius: 20px 20px 20px 0px;
            }
        }
        .block-description {
            width: 40%;
            padding: 0px 12px 0px 12px;
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            .content-description {
                h3 {
                    color: #19899b;
                    font-size: 30px;
                }
                h4 {
                    font-size: 11px;
                    padding-bottom: 12px;
                }
                p {
                    font-size: 21px;
                    padding-bottom: 12px;
                }
                .div-button {
                    cursor: pointer;
                    .center-label-button {
                        display: none;
                    }

                    button {
                        background: #19899b;
                        border: none;
                        height: 60px;
                        width: 60px;
                        border-radius: 30px;
                        cursor: pointer;
                    }
                }
                .div-button:hover {
                    border: 5px solid #19899b;
                    border-radius: 30px;
                    display: flex;
                    justify-content: space-between;
                    width: 160px;
                    height: 60px;
                    animation: display 0.5s ease forwards;

                    .center-label-button {
                        display: flex;
                        flex-wrap: wrap;
                        align-content: center;
                        padding-left: 5px;
                        p {
                            height: fit-content;
                            padding-bottom: 0;
                            font-size: 17px;
                            font-weight: 700;
                            color: #19899b;
                        }
                    }

                    button {
                        margin-top: -5px;
                        margin-right: -5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .achievement-block {
        .block-reverse {
            flex-direction: unset;
        }
        .block {
            display: block;
            border-radius: 10px;
            padding: 0;
            .block-img {
                width: 100%;
                padding: 0;
                img {
                    width: 100%;
                    border-radius: 10px 10px 0px 0px;
                }
                .img-reverse {
                    border-radius: 10px 10px 0px 0px;
                }
            }
            .block-description {
                width: 100%;
                .content-description {
                    width: 100%;
                    h3 {
                        font-size: 21px;
                        text-align: center;
                    }
                    h4 {
                        text-align: end;
                    }
                    p {
                        text-align: center;
                        font-size: 16px;
                    }
                    .content-center-button {
                        display: flex;
                        justify-content: center;
                        padding-bottom: 24px;
                        .div-button,
                        .div-button:hover {
                            cursor: pointer;
                            border: 5px solid #19899b;
                            border-radius: 30px;
                            display: flex;
                            justify-content: space-between;
                            width: 120px;
                            height: 40px;
                            animation: display 0.5s ease forwards;
                            .center-label-button {
                                display: none;
                                display: flex;
                                flex-wrap: wrap;
                                align-content: center;
                                padding-left: 5px;
                                p {
                                    height: fit-content;
                                    padding-bottom: 0;
                                    font-size: 13px;
                                    font-weight: 700;
                                    color: #19899b;
                                }
                            }

                            button {
                                background: #19899b;
                                border: none;
                                height: 40px;
                                width: 40px;
                                border-radius: 30px;
                                cursor: pointer;
                                margin-top: -5px;
                                margin-right: -5px;
                                img {
                                    width: 25px;
                                    height: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
