.navbar {
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 0;
    padding: 0 20px;
    background-color: white;

    h2{
        margin-bottom: 0;
    }
    
    .content-navbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        height: 100px;

        .block-logo-name {
            display: flex;
            cursor: pointer;
            img {
                width: 80px;
            }
            .div-logo {
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                h2 {
                    font-size: 45px;
                    color: #142833;
                    padding-left: 12px;
                }
            }
        }
        .mobile-set-up {
            display: none;
        }
        .menu {
            display: flex;
            ul {
                display: flex;
                align-content: center;
                flex-wrap: wrap;
                li {
                    list-style-type: none;
                    padding: 0 12px;
                    font-size: 25px;
                    color: #142833;

                    display: flex;
                    align-content: center;
                    flex-wrap: wrap;
                    a {
                        height: fit-content;
                        text-decoration: none;
                        color: unset;
                    }
                    a:hover {
                        font-size: 30px;
                        transition: 0.25s;
                    }
                    .activated {
                        font-size: 30px;
                        color: #19899b;
                    }
                }
                .social-networks-mobile {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .navbar {
        display: block;
        height: fit-content;

        .content-navbar {
            height: 80px;
            .block-logo-name {
                display: none;
            }

            .mobile-set-up {
                display: flex;
                padding: 20px 0px;
                width: 100%;
                .block-logo-mobile {
                    display: none;
                }
                .burger {
                    display: flex;
                    align-content: center;
                    flex-wrap: wrap;
                    justify-content: end;
                    width: 100%;
                    img {
                        cursor: pointer;
                    }
                    .expanded {
                        display: none;
                    }
                }
            }
            .menu {
                display: none;
            }
        }
    }
    .navbar-expanded-responsive {
        background-color: hsla(201, 44%, 14%, 0.9);

        .content-navbar {
            .mobile-set-up {
                .block-logo-mobile {
                    display: flex;
                    img {
                        width: 30px;
                    }
                    h2 {
                        padding-left: 5px;
                        color: white;
                    }
                }
                .burger {
                    .normal {
                        display: none;
                    }
                    .expanded {
                        display: block;
                    }
                }
            }

            .menu {
                position: absolute;
                width: 100%;
                text-align: center;
                display: block;
                margin-left: -20px;
                background-color: hsla(201, 44%, 14%, 0.9);
                transition: 0.5s;
                top: 80px;

                ul {
                    width: 100%;
                    display: block;
                    padding-bottom: 12px;
                    li {
                        display: block;
                        color: white;
                        margin: 15px 0;
                        a:hover {
                            font-size: unset;
                            transition: unset;
                        }
                        .activated {
                            font-size: unset;
                            font-weight: 600;
                            color: white;
                        }
                    }
                    .social-networks-mobile {
                        display: flex;
                        justify-content: center;
                        a {
                            border-radius: 30px;
                            width: 45px;
                            height: 45px;
                            background-color: white;
                            margin: 0 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

/**
ANIMATION
**/
.hidde-navbar {
    animation: hidde 0.2s ease forwards;
}

.display-navbar {
    animation: display 0.2s ease forwards;
    box-shadow: 0px -10px 15px;
}

@keyframes hidde {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes display {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        display: flex;
    }
}
