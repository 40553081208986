@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
$font-1: "DM Sans", sans-serif;
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    height: 1vh;
    font-family: $font-1;
    color: #142833;

    .container {
        max-width: 1280px;
        margin: auto;
    }

    h2 {
        font-size: 45px;
        margin-bottom: 24px;
    }

    
@media screen and (max-width: 1080px) {
    h2 {
        font-size: 25px;
        margin-bottom: 12px;
    }
}
}
