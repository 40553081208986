.home-view {
    .main-content {
        height: 100vh;
        display: flex;
        justify-content: center;
        .content {
            display: flex;
            justify-content: center;
            .logo {
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                width: 30%;
                img {
                    width: 100%;
                }
            }
            .block-description {
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                width: 30%;
                .center-content {
                    h1 {
                        color: #19899b;
                    }
                    h2 {
                        font-size: 21px;
                    }
                }
            }
        }
        a {
            position: absolute;
            width: 50px;
            animation: arrow-move 3s linear infinite;
            top: 85vh;
            opacity: 0.8;
            cursor: pointer;
            img {
                width: 100%;
            }
        }
        a:hover{
            opacity: 1;
        }
        .hidde-a{
            display: none;
        }

        @keyframes arrow-move {
            0% {
                transform: translateY(-20%);
            }
            25% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(20%);
            }
            75% {
                transform: translateY(0);
            }
            100% {
                transform: translateY(-20%);
            }
        }
    }

    .achievement {
        background-color: #142833;
        padding: 48px 24px;
        .block-achievement {
            padding: 24px 0;
        }
        h2 {
            color: white;
        }
    }
    .skills {
        padding: 48px 24px;
        h2 {
            color: #19899b;
        }
        .skills-block {
            display: flex;
            .skill {
                width: 33.3%;
                padding: 12px;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .home-view {
        .main-content {
            height: 100vh;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .content {
                display: block;
                .logo {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: center;
                    width: 100%;
                    img {
                        width: 60%;
                    }
                }
                .block-description {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: center;
                    width: 100%;
                    .center-content {
                        width: 60%;
                        text-align: center;
                        h1 {
                            color: #19899b;
                        }
                        h2 {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .achievement {
            .block-achievement {
                padding: 12px 0;
            }
        }
        .skills {
            .skills-block {
                display: block;
                .skill {
                    width: 100%;
                    padding: 12px 0px;
                }
            }
        }
    }
}

.display-logo {
    animation: appear 1s ease forwards;
}

.display-from-right {
    animation: appear-from-right 1s ease forwards;
}

@media screen and (max-width: 1080px) {
    .display-logo {
        animation: appear-from-top 1s ease forwards;
    }
    .display-from-right {
        animation: unset;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes appear-from-top {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
