.footer {
    background-color: #142833;
    padding: 24px;
    text-align: center;
    h2 {
        color: white;
        margin-bottom: 12px;
        font-weight: 200;
    }
    .block-social-networks {
        display: flex;
        justify-content: center;
        .circle {
            height: 70px;
            width: 70px;
            border-radius: 40px;
            margin: 0 10px;
            background-color: white;
            display: flex;
            text-align: center;
            justify-content: center;
            cursor: pointer;
            img {
                width: 45px;
            }
        }
        .circle:hover{
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
}
