.cookies {
    position: fixed;
    bottom: 30px;
    z-index: 9999999;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 30px;
    padding: 20px;
    padding-right: 50px;
    border: rgba(117, 172, 253, 1) 1px solid;
    width: 800px;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    span {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
        font-weight: 300;
        display: flex;
        color: #ffffff;
        width: 60%;
        padding-left: 5%;
    }
    button {
        font-family: 'Work Sans', sans-serif;
        font-weight: normal;
        font-weight: 300;
        display: flex;
        text-align: center;
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 40px;
        padding-left: 40px;
        border-radius: 30px;
        border: none;
        backdrop-filter: blur(44px);
    }
    .accept {
        border: 1px solid #75acfd;
        background: linear-gradient(93.31deg, #75acfd 2.64%, #9ecdf9 91.89%);
    }
    .accept:hover {
        background: linear-gradient(93.31deg,rgba(117, 172, 253, 0.2) 2.64%, rgba(158, 205, 249, 0.2) 91.89%);
        color: #fff;
        border: 1px solid #75ACFD;
        cursor: pointer;
    }
    .decline {
        color: white;
        border: 1px solid #75acfd;
        background-color: rgba(0, 0, 0, 0);
    }
    .decline:hover {
        background: linear-gradient(93.31deg,rgba(117, 172, 253, 0.2) 2.64%, rgba(158, 205, 249, 0.2) 91.89%);
        color: #fff;
        border: 1px solid #75ACFD;
        cursor: pointer;
    }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .cookies {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(40px);

        -webkit-backdrop-filter: blur(40px);
    }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .cookies {
        background: rgba(0, 0, 0, 0.7);
    }
}
